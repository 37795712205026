@import url(https://fonts.googleapis.com/css?family=Ubuntu);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3BpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDplOTMxMTU5YS02NjE2LTQ0ZGUtYTRiYy0wY2U5YzY3ZWIxOWIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NEM3MjQyQTIxQ0RBMTFFOUE3MkJBOTAwRjNGMkIxMDgiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NEM3MjQyQTExQ0RBMTFFOUE3MkJBOTAwRjNGMkIxMDgiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0QzcyNDI5RDFDREExMUU5QTcyQkE5MDBGM0YyQjEwOCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0QzcyNDI5RTFDREExMUU5QTcyQkE5MDBGM0YyQjEwOCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PiSGz8oAAAAOSURBVHjaYmBgYAAIMAAABAABmwPqCQAAAABJRU5ErkJggg==);
  background-color: #000000;
  background-repeat: repeat;
  color: #ffffff;
  font-family: Ubuntu, sans-serif; }
  body a, body a:hover {
    text-decoration: none;
    color: #ffffff; }
  body h1, body h2 {
    font-weight: lighter;
    font-family: Ubuntu, sans-serif; }
  body h1 {
    font-size: 25px; }
  body h2 {
    font-size: 22px; }

div.App {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start; }
  div.App header {
    position: relative;
    width: 100%;
    height: 56vw; }
    @media (max-width: 1500px) {
      div.App header {
        height: 672px; } }
    @media (min-width: 920px) {
      div.App header {
        height: 840px; } }
    div.App header div.video {
      position: absolute;
      top: 0;
      bottom: 3px;
      left: 0;
      right: 0;
      z-index: 0;
      overflow: hidden;
      visibility: visible;
      background-image: url(/static/media/video.1da10f68.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      text-align: center; }
      div.App header div.video video {
        min-height: 100%;
        min-width: 100%;
        width: auto;
        max-height: 200%;
        max-width: 200%;
        visibility: visible; }
        @media (max-width: 600px) {
          div.App header div.video video {
            max-width: 500%; } }
    div.App header div.content {
      visibility: visible;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      background-image: url(/static/media/masque-header.4237b2f3.png);
      background-size: cover;
      background-position-x: center;
      background-position-y: bottom;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: stretch;
      padding-top: 20px; }
      @media (max-width: 600px) {
        div.App header div.content {
          max-width: 500%; } }
      div.App header div.content .logo {
        margin-top: 132px;
        margin-left: 20px;
        margin-right: 20px; }
      div.App header div.content .text {
        margin-top: 142px;
        width: 470px;
        margin-right: 75px; }
        div.App header div.content .text .littleLine {
          margin: 27px 0; }
        div.App header div.content .text h2 {
          margin-top: 50px;
          margin-bottom: 0; }
        div.App header div.content .text h1 {
          margin-top: 3px;
          margin-bottom: 0; }
      @media (max-width: 920px) {
        div.App header div.content {
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: start;
          align-items: flex-start; }
          div.App header div.content .logo {
            margin: 0 0 0 50px;
            width: 166px;
            height: 145px; }
            div.App header div.content .logo img {
              width: 100%;
              height: 100%; }
          div.App header div.content .text {
            margin: 10px 50px;
            width: auto; } }
    @media (max-width: 920px) and (max-width: 420px) {
      div.App header div.content .text {
        margin-top: -2em; } }
      @media (max-width: 920px) {
            div.App header div.content .text .littleLine {
              margin: 27px 0; }
            div.App header div.content .text h2 {
              margin-top: 50px;
              margin-bottom: 0; }
            div.App header div.content .text h1 {
              margin-top: 3px;
              margin-bottom: 0; } }
  div.App p {
    margin-top: 0;
    letter-spacing: 1px;
    line-height: 23px;
    text-align: left;
    font-size: 16px; }
  div.App div.bodyContent {
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    width: 920px;
    margin: -140px auto 0 auto; }
    div.App div.bodyContent .distribGif {
      margin-bottom: 1em; }
    @media (max-width: 920px) {
      div.App div.bodyContent {
        width: 100%;
        margin: -50px 0 0 0; } }
    div.App div.bodyContent .games, div.App div.bodyContent .distributor, div.App div.bodyContent .catalog {
      width: 100%; }
      div.App div.bodyContent .games .text, div.App div.bodyContent .distributor .text, div.App div.bodyContent .catalog .text {
        margin-left: 100px;
        width: 370px;
        z-index: 1; }
        @media (max-width: 920px) {
          div.App div.bodyContent .games .text, div.App div.bodyContent .distributor .text, div.App div.bodyContent .catalog .text {
            margin-left: 0;
            width: auto;
            padding: 0 50px; } }
        div.App div.bodyContent .games .text h1, div.App div.bodyContent .distributor .text h1, div.App div.bodyContent .catalog .text h1 {
          margin-bottom: 0; }
        div.App div.bodyContent .games .text .littleLine, div.App div.bodyContent .distributor .text .littleLine, div.App div.bodyContent .catalog .text .littleLine {
          margin: 27px 0; }
        div.App div.bodyContent .games .text img, div.App div.bodyContent .distributor .text img, div.App div.bodyContent .catalog .text img {
          margin-top: 20px;
          z-index: 0;
          max-width: 80%; }
      div.App div.bodyContent .games .brands, div.App div.bodyContent .distributor .brands, div.App div.bodyContent .catalog .brands {
        overflow: hidden;
        margin-top: -185px;
        z-index: 10;
        width: 950px;
        height: auto;
        margin-left: -40px; }
        @media (max-width: 920px) {
          div.App div.bodyContent .games .brands, div.App div.bodyContent .distributor .brands, div.App div.bodyContent .catalog .brands {
            width: 100%;
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 0; } }
        div.App div.bodyContent .games .brands svg, div.App div.bodyContent .distributor .brands svg, div.App div.bodyContent .catalog .brands svg {
          width: 100%; }
          div.App div.bodyContent .games .brands svg .growMe, div.App div.bodyContent .distributor .brands svg .growMe, div.App div.bodyContent .catalog .brands svg .growMe {
            transition: all .2s ease-in-out; }
            div.App div.bodyContent .games .brands svg .growMe:hover.brand, div.App div.bodyContent .distributor .brands svg .growMe:hover.brand, div.App div.bodyContent .catalog .brands svg .growMe:hover.brand {
              -webkit-transform: translate(-3px, -3px) scale(1.01);
                      transform: translate(-3px, -3px) scale(1.01); }
            div.App div.bodyContent .games .brands svg .growMe:hover.game, div.App div.bodyContent .distributor .brands svg .growMe:hover.game, div.App div.bodyContent .catalog .brands svg .growMe:hover.game {
              -webkit-transform: translate(-5px, -3px) scale(1.01);
                      transform: translate(-5px, -3px) scale(1.01); }
          div.App div.bodyContent .games .brands svg .over, div.App div.bodyContent .distributor .brands svg .over, div.App div.bodyContent .catalog .brands svg .over {
            transition: all .2s ease-in-out;
            opacity: 0; }
            div.App div.bodyContent .games .brands svg .over:hover, div.App div.bodyContent .distributor .brands svg .over:hover, div.App div.bodyContent .catalog .brands svg .over:hover {
              opacity: 0.9;
              cursor: pointer; }
    div.App div.bodyContent .distributor {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: stretch;
      justify-content: space-between; }
      @media (max-width: 920px) {
        div.App div.bodyContent .distributor {
          flex-direction: column;
          flex-wrap: nowrap;
          align-items: stretch;
          justify-content: center; } }
      div.App div.bodyContent .distributor .text {
        margin-top: 15px;
        margin-right: 30px; }
      div.App div.bodyContent .distributor .distributors {
        flex-grow: 1;
        margin: 0 2em;
        max-width: 344px;
        text-align: center; }
        @media (max-width: 920px) {
          div.App div.bodyContent .distributor .distributors {
            width: 100%;
            max-width: 340px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 0; } }
        div.App div.bodyContent .distributor .distributors svg {
          display: inline; }
          div.App div.bodyContent .distributor .distributors svg .over {
            transition: all .2s ease-in-out;
            opacity: 0; }
            div.App div.bodyContent .distributor .distributors svg .over:hover {
              opacity: 0.9;
              cursor: pointer; }
        div.App div.bodyContent .distributor .distributors .visible {
          visibility: visible;
          opacity: 1;
          transition: visibility 0.5s, opacity 0.5s linear; }
        div.App div.bodyContent .distributor .distributors .hide {
          visibility: hidden;
          opacity: 0;
          transition: visibility 0.5s, opacity 0.5s linear; }
    div.App div.bodyContent .catalog {
      text-align: center; }
      div.App div.bodyContent .catalog img {
        max-width: 70vw; }
  div.App footer .headImage {
    content: ' ';
    height: 345px;
    width: 100%; }
    div.App footer .headImage img {
      width: 100%;
      height: 100%; }
    @media (max-width: 2500px) {
      div.App footer .headImage {
        background-image: url(/static/media/footer.404e6820.png);
        background-size: cover;
        background-position-x: center;
        background-position-y: bottom; }
        div.App footer .headImage img {
          display: none; } }
    @media (min-width: 2449px) {
      div.App footer .headImage {
        height: 380px; } }
  div.App footer .background {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MDZGMENCMjAxRDZBMTFFOUE3MkJBOTAwRjNGMkIxMDgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MDZGMENCMjExRDZBMTFFOUE3MkJBOTAwRjNGMkIxMDgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0QzcyNDJBMzFDREExMUU5QTcyQkE5MDBGM0YyQjEwOCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0QzcyNDJBNDFDREExMUU5QTcyQkE5MDBGM0YyQjEwOCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PvBc2nUAAAAOSURBVHjaYmDw7AMIMAABJADYK7jdrQAAAABJRU5ErkJggg==);
    padding-bottom: 0; }
  div.App footer .content {
    margin-top: -50px;
    width: 920px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row; }
    @media (max-width: 920px) {
      div.App footer .content {
        width: 100%;
        flex-direction: column; } }
    div.App footer .content .text {
      margin-left: 100px;
      margin-right: 100px;
      width: 370px;
      z-index: 1; }
      @media (max-width: 920px) {
        div.App footer .content .text {
          margin-left: 0;
          margin-right: 0;
          width: auto;
          padding: 0 50px; } }
      div.App footer .content .text h1 {
        margin-bottom: 0; }
      div.App footer .content .text .littleLine {
        margin: 27px 0; }
      div.App footer .content .text .logo {
        text-align: center;
        margin-top: 2em; }
        @media (max-width: 920px) {
          div.App footer .content .text .logo {
            text-align: left; } }
    div.App footer .content .contactForm {
      flex-grow: 1; }
      @media (max-width: 920px) {
        div.App footer .content .contactForm {
          width: 80%;
          margin: 1em auto 0 auto;
          flex-direction: column; } }
    div.App footer .content form input[type=text], div.App footer .content form input[type=email], div.App footer .content form textarea {
      display: block;
      border: solid 1px #2778C9;
      margin-bottom: 1em;
      background: #134679;
      padding: 0 20px;
      margin: 1em -20px 0  -20px;
      min-height: 50px;
      width: 100%;
      color: #ffffff;
      outline: none; }
      div.App footer .content form input[type=text]::-webkit-input-placeholder, div.App footer .content form input[type=email]::-webkit-input-placeholder, div.App footer .content form textarea::-webkit-input-placeholder {
        color: #d3d3d3; }
      div.App footer .content form input[type=text]::-ms-input-placeholder, div.App footer .content form input[type=email]::-ms-input-placeholder, div.App footer .content form textarea::-ms-input-placeholder {
        color: #d3d3d3; }
      div.App footer .content form input[type=text]::placeholder, div.App footer .content form input[type=email]::placeholder, div.App footer .content form textarea::placeholder {
        color: #d3d3d3; }
      div.App footer .content form input[type=text]:focus, div.App footer .content form input[type=email]:focus, div.App footer .content form textarea:focus {
        box-shadow: 0 0 5px #2778C9; }
    div.App footer .content form .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #eee; }
    div.App footer .content form input[type=checkbox] {
      border: solid 1px #2778C9;
      margin-bottom: 1em;
      background: #134679;
      padding: 0 20px; }
    div.App footer .content form textarea {
      padding-top: 20px; }
    div.App footer .content form .rgpd {
      margin-top: 2em; }
    div.App footer .content form .endForm {
      margin-top: 2em;
      margin-bottom: 1em;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      align-items: center; }
      @media (max-width: 920px) {
        div.App footer .content form .endForm {
          flex-direction: column-reverse;
          justify-content: stretch;
          align-items: stretch; }
          div.App footer .content form .endForm .textLeg {
            text-align: right;
            margin: 0 0 1em 0; } }
      div.App footer .content form .endForm button {
        background: #212121;
        border-radius: 0;
        border: none;
        color: #ffffff;
        height: 66px;
        font-size: 16px;
        padding: 15px;
        text-transform: uppercase; }
        div.App footer .content form .endForm button img {
          vertical-align: middle;
          margin: 0.5em; }
      div.App footer .content form .endForm .text {
        vertical-align: middle;
        margin: 0; }
  div.App footer .footerBar {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center; }
    div.App footer .footerBar > img {
      background: #212121; }
      div.App footer .footerBar > img:last-of-type {
        -webkit-transform: scaleX(-1);
                transform: scaleX(-1); }
      @media (max-width: 920px) {
        div.App footer .footerBar > img {
          display: none; } }
    div.App footer .footerBar .links {
      background: #212121;
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      width: 920px;
      padding: 10px;
      height: 80px; }
      @media (max-width: 920px) {
        div.App footer .footerBar .links {
          width: 100%; } }
      div.App footer .footerBar .links > * {
        height: 80px;
        line-height: 80px;
        padding: 0 20px; }
        div.App footer .footerBar .links > *:hover {
          cursor: pointer;
          color: #eeeeee; }

.grecaptcha-badge {
  visibility: collapse !important; }

